import * as React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEO {
  description?: string
  lang?: string
  keywords?: string[]
  title?: string
}

interface Site {
  site: {
    siteMetadata: {
      title: string
      language: string
      description: string
      author: string
      siteUrl: string
    }
  }
}
const SEO: React.FC<SEO> = ({ description, lang, keywords = [], title }) => {
  const { site }: Site = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            language
            title
            description
            keywords
            author
            siteUrl
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const defaultImage = site.siteMetadata.siteUrl + "/icons/icon-96x96.png"
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: defaultImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: defaultImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
          : []
      )}
    >
        <script type="application/javascript">
          {`
            (function(window, document) {
              function main() {
                // GoogleAdSense読込み
                var ad = document.createElement('script');
                ad.type = 'text/javascript';
                ad.async = true;
                // 新コードの場合、サイト運営者IDを書き換えてコメントアウトを外す
                ad.dataset.adClient = 'ca-pub-6232711431474525';
                ad.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
                var sc = document.getElementsByTagName('script')[0];
                sc.parentNode.insertBefore(ad, sc);
              }
            
              // 遅延読込み
              var lazyLoad = false;
              function onLazyLoad() {
                if (lazyLoad === false) {
                  // 複数呼び出し回避 + イベント解除
                  lazyLoad = true;
                  window.removeEventListener('scroll', onLazyLoad);
                  window.removeEventListener('mousemove', onLazyLoad);
                  window.removeEventListener('mousedown', onLazyLoad);
                  window.removeEventListener('touchstart', onLazyLoad);
                  window.removeEventListener('keydown', onLazyLoad);
                  main();
                }
              }
              window.addEventListener('scroll', onLazyLoad);
              window.addEventListener('mousemove', onLazyLoad);
              window.addEventListener('mousedown', onLazyLoad);
              window.addEventListener('touchstart', onLazyLoad);
              window.addEventListener('keydown', onLazyLoad);
              window.addEventListener('load', function() {
                // ドキュメント途中（更新時 or ページ内リンク）
                if (window.pageYOffset) {
                  onLazyLoad();
                }
              //何もアクションがないときは指定秒数後に読み込み開始（ミリ秒）
              window.setTimeout(onLazyLoad,3000)
              });
            })(window, document);
          `}
        </script>
    </Helmet>
  )
}

export default SEO
